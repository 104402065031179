import React from 'react';
import './button.scss';

export interface ButtonProps {
  type?: 'primary'  | 'secondary' | 'link';
  variant?: 'danger' | 'warning';
  title?: string;
  licon?: string;
  ticon?: string;
  lean?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
}

export default function Button(props: ButtonProps) {
  const type = props.type || 'primary';
  const variant = props.variant || '';
  return (
    <button
      disabled={props.disabled}
      className={
        `${type} ${variant} ${props.lean ? 'lean' : ''} ${props.className}`
      }
      onClick={props.onClick}
    >
      {props.licon && <i className={`${props.licon}`} />}
      {props.title}
      {props.ticon && <i className={`${props.ticon}`} />}
    </button>
  );
}
