import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TNC from './pages/Legal/TermsNConditions';
import FallBackLoader from './components/FallBackLoader';

const Home = React.lazy(() => import('./pages/Home'));
const ProjectEditor = React.lazy(() => import('./pages/ProjectEditor'));
const MyLibrary = React.lazy(() => import('./pages/MyLibrary'));
const Test = React.lazy(() => import('./pages/Test'));
const About = React.lazy(() => import('./pages/About'));
const NoMatch = React.lazy(() => import('./pages/NoMatch'));

function App() {
  return (
    <React.Suspense fallback={<FallBackLoader />}>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="project/:projectId" element={<ProjectEditor />} />
          <Route path="project/:projectId/file/:fileId" element={<ProjectEditor />} />
          <Route path="my-library/*" element={<MyLibrary />} />
          <Route path="about/*" element={<About />} />
          <Route path="tnc" element={<TNC />} />
          <Route path="test/*" element={<Test />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default App;
