import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import useTNC from '../../hooks/useTNC';

export default function TNC() {
  const navigate = useNavigate();
  const { shouldShowTNC, acceptTNC, denyTnc } = useTNC();

  function onAccept() {
    acceptTNC();
    navigate(-1);
  }

  return (
    <>
      <Header />
      {shouldShowTNC && (
        <div className="bg-yellow-200 w-full p-4 shadow-inner mt-4">
          <div className="flex flex-wrap gap-1">
            <Button
              type="secondary"
              title="Accept all"
              className="text-green-800 font-bold flex-1"
              onClick={onAccept}
            />
            <Button
              type="secondary"
              title="Deny all"
              className="text-red-600 font-bold flex-1"
              onClick={denyTnc}
            />
          </div>
        </div>
      )}
      <main className="px-4 py-2">
        <h2>Terminology</h2>
        <p>
          In this document, <b>RichText.org</b> is referred as `This website`,
          `The website` or `This platform`.
        </p>
        <h2>beta</h2>
        <p>
          This website is under beta which means this will go under significant changes and is unstable.
          Data created may be lost during beta phase.
        </p>
        <h2>Anonymous Data Collection</h2>
        <p>
          This website anonymously tracks various events on its pages. This
          entails monitoring which actions are taken on this website and which
          are not. However, it does not identify individuals associated with
          these actions.
        </p>
        <h3>Why is it required?</h3>
        <p>This helps to improve the features that are not being utilized.</p>
        <h3>What Does it mean for You?</h3>
        <p>
          Since all data is anonymous, individual behavior is not tracked. The
          website is solely interested in the overall number of events.
        </p>
        <h2 className="mt-4">Cookies</h2>
        <p>
          Only necessary cookies are stored which are required for this platform
          to function.
        </p>

        <h2 className="mt-4">Storage</h2>
        <p>
          When you create projects locally in your browser, they are visible
          only to you or anyone using your browser. This website does not have
          access to information about the projects you create or the data you
          save. Your projects and files never leave your browser.
        </p>
        <h3>What Does it Mean for You?</h3>
        <p>
          This enhanced privacy also comes with increased responsibility. You
          have full control over your data, and if it's lost, this platform has
          no way to recover it.
        </p>

        <h2 className="mt-4">Intellectual Property &amp; Ownership</h2>
        <p>
          The content you create remains your own. By using this platform, you
          do not transfer any intellectual property rights to this website. This
          website provides a platform for content creation, and all content
          remains your responsibility at all times.
        </p>
        <p>
          <em>
            It is your responsibility to ensure that you comply with copyright
            laws and all other applicable laws.
          </em>
        </p>

        <h2 className="mt-4">Modifications</h2>
        <p>
          The website reserve the right, at its sole discretion, to modify or
          replace these terms at any time.
        </p>
        <p>
          By continuing to access or use the services provided by this website
          after the revisions to the terms become effective, you agree to be
          bound by the revised terms. If you do not agree to the new terms,
          please discontinue using the services provided by this website.
        </p>
      </main>
      {shouldShowTNC && (
        <div className="bg-yellow-200 w-full p-4 shadow-inner">
          <div className="flex flex-wrap gap-1">
            <Button
              type="secondary"
              title="Accept all"
              className="text-green-800 font-bold flex-1"
              onClick={onAccept}
            />
            <Button
              type="secondary"
              title="Deny all"
              className="text-red-600 font-bold flex-1"
              onClick={denyTnc}
            />
          </div>
        </div>
      )}
    </>
  );
}
