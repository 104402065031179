import React from 'react';
import { Link } from 'react-router-dom';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';

import Button from './Button';

function Header() {
  const [isDrawerOpen, toggleDrawer] = React.useState(false);
  
  const linkConfig = [
    {
      path: '/my-library',
      text: 'My library',
      icon: 'fas fa-book',
    },
    {
      path: '/about',
      text: 'About',
      icon: 'fas fa-building',
    },
    {
      path: '/tnc',
      text: 'Terms & Conditions',
      icon: 'fas fa-handshake',
    },
  ];
  return (
    <header className="bg-logo-back p-4 flex justify-between items-center sticky top-0 z-10">
      <Link to="/" className="flex">
        <h1 className="text-logo-front flex items-baseline">
          <i className="fas fa-money-check-pen mr-2 text-3xl" />
          RichText.org
        </h1>
        <span className="text-buttons-secondary">beta</span>
      </Link>
      <nav className="hidden large-tab:flex gap-4">
        {linkConfig.map(({ path, text, icon }) => (
          <Link to={path} key={path} className="text-blue-300">
            <i className={`${icon} mr-2 text-md`} />
            <span>{text}</span>
          </Link>
        ))}
      </nav>
      <div className="flex large-tab:hidden gap-4">
        <Button
          type="link"
          licon="fas fa-bars"
          className="!text-yellow-600 text-2xl"
          onClick={() => toggleDrawer(true)}
        />
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          size="full"
          onClose={() => toggleDrawer(false)}
        >
        <DrawerOverlay />
        <DrawerContent>
          <div className="bg-logo-back h-full w-full px-4 py-2">
            <div className="flex justify-between mb-4">
              <Link to="/" className="flex">
                <h1 className="text-logo-front flex items-baseline">
                  <i className="fas fa-money-check-pen mr-2 text-3xl" />
                  RichText.org
                </h1>
                <span className="text-buttons-secondary">beta</span>
              </Link>
              <Button
                type="link"
                licon="fas fa-close"
                className="!text-yellow-600 text-2xl"
                onClick={() => toggleDrawer(false)}
              />
            </div>
            <div className="flex flex-col gap-4">
              {linkConfig.map(({ path, text, icon }) => (
                <Link to={path} key={path} className="text-blue-300">
                  <i className={`${icon} mr-2 text-md`} />
                  <span>{text}</span>
                </Link>
              ))}
            </div>
            </div>
          </DrawerContent>
        </Drawer>
      </div>
    </header>
  );
}

export default Header;
