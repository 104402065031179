import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';

import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './assets/css/index.css';
import './assets/css/fontawesome.all.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
