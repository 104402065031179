import React from 'react';

import { TNC_KEY } from '../constants';

enum TNC_STATUS {
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  NONE = 'none'
}

export default function useTNC() {
  const [tncStatus, setTncStatus] = React.useState(TNC_STATUS.NONE);

  React.useEffect(() => {
    const statusFromLS = localStorage.getItem(TNC_KEY) as TNC_STATUS || TNC_STATUS.NONE;
    setTncStatus(statusFromLS);
  }, []);

  function onTNCAction(action: TNC_STATUS) {
    localStorage.setItem(TNC_KEY, action);
    setTncStatus(action);
  }

  function acceptTNC() {
    onTNCAction(TNC_STATUS.ACCEPTED);
  }

  function denyTnc() {
    onTNCAction(TNC_STATUS.DENIED);
  }

  return {
    shouldShowTNC: tncStatus !== TNC_STATUS.ACCEPTED,
    isDenied: tncStatus === TNC_STATUS.DENIED,
    acceptTNC,
    denyTnc,
  };
}